<template>
    <div class="write-evalute">
        <div class="evalute-box">
            <p class="title">{{$t("evaluate.babyReceived")}}</p>
            <p class="placeholder" >{{$t("evaluate.pleaseEvaluate")}}</p>
            <div class="border-line"></div>
            <p class="goods-infor">
                <img :src="UrlData.goodsUrl" >
                <span>{{$t("evaluate.synthesisEvalaute")}}</span>
            </p>
            <div class="evalute-infor">
                <template v-for="i in 5" >
                    <img src="@/assets/img/evaluate-active-icon.png" v-if="score>=i" :key="i" @click="score = i">
                    <img src="@/assets/img/evaluate-icon.png" v-else :key="i" @click="score = i">
                </template>
            </div>
        </div>
        <div class="describe">
            <p class="title">
                {{$t("evaluate.desc")}}<span> ({{$t("evaluate.optional")}})</span>
            </p>
            <van-field
                v-model="comments"
                rows="3"
                class="fieldDesc"
                autosize
                type="textarea"
                maxlength="50"
                :placeholder='$t("evaluate.descPlaceholder")'
                show-word-limit
            />
            <div class="upload">
                <div class="files">
                    <van-uploader v-model="uploadimg" multiple :max-count="10000" />

                    <!-- <input type="file" @change="uploadChange"> -->
                </div>
            </div>
        </div>
        <div class="anonymous">
            <van-checkbox v-model="checked">
                {{$t("evaluate.anonymousEvaluate")}}
                <template #icon="props">
                    <img class="img-icon" :src="props.checked ? require('@/assets/img/evaluate-anonymous-active.png') : require('@/assets/img/evaluate-anonymous.png')" />
                </template>
            </van-checkbox>
        </div>
        <div class="submit-btn" @click="submitEvalute">
            {{$t("evaluate.submitEvaluate")}}
        </div>
    </div>
</template>

<script>
import { evalute_Add } from "@/api/user";
export default {
    data(){
        return{
            UrlData:{},
            score:0,
            comments:"",
            uploadimg:[],
            checked:true
        }
    },
    created(){
        // console.log();
        this.UrlData = this.$route.query;
        document.title = this.$t("evaluate.evaluation");
    },
    methods:{
       async  uploadOss(index){
           console.log(index);
            let result = await this.$uploadAxios.post('upload/base64-upload', {data:this.uploadimg[index].content,fileName:this.uploadimg[index].file.name});
            // console.log(result);
            if(result.errorCode == 0){
                this.uploadimg[index].imgUrl = result.data;
            }
            if(index < (this.uploadimg.length-1)){
                index++;
                await this.uploadOss(index)
            }
        },
        async submitEvalute(){
            if(this.score == 0){
                this.$toast(this.$t("evaluate.pleaseEvaluat"))
                return;
            }
            // console.log(this.uploadimg);
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            if(this.uploadimg.length>0){
                await this.uploadOss(0);
            }
            // console.log(this.uploadimg);
            let imgUrls = [];
            for(let it in this.uploadimg){
                imgUrls.push(this.uploadimg[it].imgUrl)
            }
            let datadd = {
                orderId:this.UrlData.orderId,
                goodsId:this.UrlData.goodsId,
                comments:this.comments,
                score:this.score,
                imgUrls
            }
            let result = await evalute_Add(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.$router.push({
                    path:"/my/evalute_success"
                })
            }else{
                this.$toast(this.$t("evaluate.evaluatFail"));
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    .write-evalute{
        background: #F5F6FA;
        min-height: 100%;
        overflow: auto;
        .evalute-box{
            margin: 0.4rem 0;
            border-radius: 0.32rem;
            padding: 1rem 0.6rem;
            background: #fff;
            text-align: center;
            .title{
                font-size: 0.72rem;
                font-weight: 500;
                color: #303133;
                line-height: 1rem;
            }
            .placeholder{
                margin-top: 0.3rem;
                font-size: 0.44rem;
                font-weight: 400;
                color: #C0C4CC;
                line-height: 0.6rem;
            }
            .border-line{
                width: 6rem;
                height: 0.04rem;
                background: #F5F6FA;
                margin: 0 auto;
                margin-top: 0.6rem;
            }
            .goods-infor{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.44rem;
                margin-bottom: 0.5rem;
                img{
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-right: 0.2rem;
                }
                span{
                    font-size: 0.6rem;
                    font-weight: 400;
                    color: #303133;
                    line-height: 0.84rem;
                }
            }
            .evalute-infor{
                display: flex;
                width: 6.12rem;
                justify-content: space-between;
                margin: 0 auto;
                img{
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }
        }
        .describe{
            padding: 0.6rem;
            background: #fff;
            border-radius: 0.32rem;
            .title{
                font-size: 0.6rem;
                font-weight: 400;
                color: #303133;
                line-height: 0.84rem;
                span{
                    font-size: 0.52rem;
                    font-weight: 400;
                    color: #C0C4CC;
                }
            }
            .fieldDesc{
                font-size: 0.52rem;
                margin-top: 0.6rem;
                padding: 0.2rem 0.3rem;
                font-weight: 400;
                color: #C0C4CC;
                background: #F5F6FA;
                line-height: 0.74rem;
                border-radius: 0.32rem;
                .van-field__word-limit{
                    font-size: 0.52rem;
                    color: #C0C4CC;
                }
            }
            .upload{
                margin-top: 0.6rem;
                .van-uploader{
                    .van-uploader__upload,.van-image {
                        width: 3.24rem;
                        height: 3.24rem;
                        // width: 100%;
                        // height: 100%;
                    }
                    .van-uploader__upload-icon{
                        font-size: 0.96rem;
                    }
                    .van-uploader__preview{
                        margin-right: 0.21rem;
                        margin-bottom: 0.21rem;
                    }
                    .van-uploader__preview-delete{
                        width: .64rem;
                        height: .64rem;
                        .van-icon {
                            font-size: 0.64rem;
                        }
                    }

                }
            }
        }
        .anonymous{
            padding: 0.4rem 0.6rem;
            .van-checkbox{
                overflow: inherit;
                font-size: .44rem;
                font-weight: 400;
                color: #C0C4CC;
                line-height: 0.6rem;
                .van-checkbox__icon {
                    height: max-content;
                    line-height: inherit;
                    img{
                        width: 0.6rem;
                        height: 0.6rem;
                    }
                }
            }
        }
        .submit-btn{
            width: 11rem;
            height: 1.6rem;
            background: #FF5252;
            border-radius: 0.8rem;
            line-height: 1.6rem;
            font-size: 0.52rem;
            font-weight: 400;
            color: #FFFFFF;
            margin: 3rem auto;
            text-align: center;

        }
    }
</style>
